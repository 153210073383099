import React, { useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { AnnotoOptions } from '@wix/wix-vod-annoto';
import { canShowComments as canShowCommentsSelector } from '../../../../../selectors/comments';
import {
  setCommentsOpen,
  setHasNewComments,
} from '../../../../../redux/actions/comments';
import {
  getLocale,
  isRTL as isRTLSelector,
} from '../../../../../selectors/app-settings';
import { isSingleVideo as isSingleVideoSelector } from '../../../../../selectors/video';
import {
  getCompId,
  getMetaSiteId,
} from '../../../../../redux/hydrated-data/hydrated-data';
import { logBi } from '../../../../../worker/actions/bi';
import { getCurrentSiteUser } from '../../../../../selectors/current-site-user';
import { requestLogin } from '../../../../../utils/auth';
import { notForPreview } from '../../../../../utils/not-for-preview';
import { storeForReload } from '../../../../../utils/reload';
import { isInFullScreen as isInFullScreenStateSelector } from '../../../../../selectors/player';
import { useHandlersAndState } from '../../../../../containers/withHandlersAndState';
import { useWidgetFocus } from '../../../../../containers/WidgetFocus';

interface AnnotoHandlersProps {
  player: any;
  videoItem: any;
  channelData: any;
  mobileMode: boolean;
}

export const AnnotoHandlers: React.FC<AnnotoHandlersProps> = ({
  player,
  videoItem,
  channelData,
  mobileMode,
}) => {
  const dispatch = useDispatch();
  const { controllerState, handlers } = useHandlersAndState();
  const currentSiteUser = useSelector(getCurrentSiteUser);
  const metaSiteId = useSelector(getMetaSiteId);
  const isSingleVideo = useSelector(isSingleVideoSelector);
  const locale = useSelector(getLocale);
  const isInFullScreen = useSelector(isInFullScreenStateSelector);
  const canShowComments = useSelector((state: any) =>
    canShowCommentsSelector(state, videoItem, channelData, currentSiteUser),
  );
  const isRTL = useSelector(isRTLSelector);
  const {
    id: videoId,
    videoSource,
    title: videoTitle,
    description: videoDescription,
  } = videoItem;
  const {
    id: channelId,
    title: channelTitle,
    description: channelDescription,
  } = channelData;
  const { annotoToken } = controllerState;
  const { widgetFocused } = useWidgetFocus();
  const compId = useSelector(getCompId);
  const annotoWidgetId = `${compId}-${videoId}`;

  const logShownBi = useCallback(
    (isShown: boolean) => {
      dispatch(
        logBi('widget.vid.showComment.shown', {
          isShown,
          isSingleVideo,
          channelID: channelId,
          videoID: videoId,
          source: videoSource,
        }),
      );
    },
    [channelId, dispatch, isSingleVideo, videoId, videoSource],
  );

  const handleUxEvent = useCallback(
    ({ name }: { name: string }) => {
      if (name === 'widget:hide') {
        dispatch(setCommentsOpen(false));
        logShownBi(false);
      }
      if (name === 'widget:show') {
        dispatch(setCommentsOpen(true));
        logShownBi(true);
      }
    },
    [dispatch, logShownBi],
  );

  const handleMetadataChange = useCallback(
    ({ comments }: { comments: { countDiff?: number } }) => {
      const { countDiff = 0 } = comments;
      const isNewCommentsAdded = countDiff > 0;

      if (isNewCommentsAdded) {
        dispatch(
          logBi('widget.vid.newComment.added', {
            isSingleVideo,
            channelID: channelId,
            videoID: videoId,
            source: videoSource,
          }),
        );
      }

      dispatch(setHasNewComments(isNewCommentsAdded));
    },
    [channelId, dispatch, isSingleVideo, videoId, videoSource],
  );

  const getMediaDetails = useCallback(() => {
    return {
      title: videoTitle || '',
      description: videoDescription || '',
      group: {
        id: channelId,
        title: channelTitle || '',
        description: channelDescription || '',
      },
    };
  }, [
    channelDescription,
    channelId,
    channelTitle,
    videoDescription,
    videoTitle,
  ]);

  const savePlayerTime = useCallback(() => {
    dispatch(
      storeForReload({
        selectedVideoId: videoId,
        savedPlayerTime: player.getCurrentTime(),
      }),
    );
  }, [dispatch, player, videoId]);

  const makeLoginRequest = useCallback(
    () =>
      notForPreview(() => {
        if (currentSiteUser) {
          handlers.requestAnnotoToken(currentSiteUser.id, metaSiteId);
        } else {
          savePlayerTime();

          if (isInFullScreen) {
            player.exitFullScreen();
          }
          dispatch(requestLogin());
        }

        dispatch(
          logBi('widget.vid.commentsLogin.requested', {
            isSingleVideo,
            channelID: channelId,
            videoID: videoId,
            source: videoSource,
          }),
        );
      }),
    [
      channelId,
      currentSiteUser,
      dispatch,
      handlers,
      isInFullScreen,
      isSingleVideo,
      metaSiteId,
      player,
      savePlayerTime,
      videoId,
      videoSource,
    ],
  );

  const handleLoginRequest = useCallback(() => {
    makeLoginRequest();
    return Promise.resolve();
  }, [makeLoginRequest]);

  const annotoOptions = useMemo<AnnotoOptions | null>(() => {
    if (!annotoToken || !canShowComments) {
      return null;
    }

    return {
      ssoToken: annotoToken,
      locale,
      ssoAuthRequestHandle: handleLoginRequest,
      mediaDetailsHandle: getMediaDetails,
      metadataChangeCallback: handleMetadataChange,
      uxEventCallback: handleUxEvent,
      mediaId: videoId,
      rtl: isRTL,
    };
  }, [
    annotoToken,
    canShowComments,
    getMediaDetails,
    handleLoginRequest,
    handleMetadataChange,
    handleUxEvent,
    isRTL,
    locale,
    videoId,
  ]);

  useEffect(() => {
    if (currentSiteUser) {
      handlers.requestAnnotoToken(currentSiteUser.id, metaSiteId);
    }
  }, [currentSiteUser, handlers, metaSiteId]);

  useEffect(() => {
    if (!annotoOptions) {
      return;
    }

    if (!window.AnnotoWidgetId) {
      // Boot annoto once for the page
      window.AnnotoWidgetId = annotoWidgetId;
      player.bootAnnoto(annotoOptions);
      return;
    }

    if (window.AnnotoWidgetId !== annotoWidgetId && widgetFocused) {
      // Load new config
      window.AnnotoWidgetId = annotoWidgetId;
      player.loadAnnotoConfig(annotoOptions);
    }
  }, [annotoOptions, annotoWidgetId, player, widgetFocused]);

  return null;
};
