import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { withFedopsLogger } from '@wix/yoshi-flow-editor';

import { getAllSettings } from '../../../../../selectors/app-settings';
import { getChannelForWidget } from '@wix/wix-vod-shared/common';
import { Picture, PictureMode } from '@wix/wix-vod-shared/components';
import { getCurrentSiteUser } from '../../../../../selectors/current-site-user';
import getVideoUrls from '../../../../../selectors/get-video-urls';
import {
  openBuyVideoOverlayFromWidget,
  openSubscribeOverlayFromWidget,
} from '../../../../../redux/actions/mobile-overlay-openners/payment-pages-actions';
import {
  openVideoPage,
  visitChannel,
} from '../../../../../redux/actions/mobile-overlay-openners/main-openners';
import {
  isChannelCoverView,
  getTitle,
  getCover,
} from '../../ui-selectors/cover-with-overlay';
import CoverActionsOverlay from '../cover-actions-overlay/cover-actions-overlay';
import { notForPreview } from '../../../../../utils/not-for-preview';
import { FedopsInteractionsNames } from '../../../../../constants/fedops-interaction-names';
import { withMobileOverlay } from '../../../../../mobile-overlay/MobileOverlayProvider';

const mapStateToProps = (state, props) => ({
  channelData: getChannelForWidget(state),
  currentSiteUser: getCurrentSiteUser(state),
  videoUrls: getVideoUrls(state, props),
  isChannelCoverView: isChannelCoverView(state, props),
  coverUrl: getCover(state, props),
  title: getTitle(state, props),
  appSettings: getAllSettings(state),
});

const mapDispatchToProps = {
  openBuyVideoOverlay: openBuyVideoOverlayFromWidget,
  openSubscribeOverlay: openSubscribeOverlayFromWidget,
  openVideoPage,
  visitChannel,
};

class CoverWithOverlay extends React.Component {
  static propTypes = {
    videoItem: PropTypes.object,
    channelData: PropTypes.object,
    currentSiteUser: PropTypes.object,
    videoUrls: PropTypes.object,
    appSettings: PropTypes.object,

    openVideoPage: PropTypes.func,
    visitChannel: PropTypes.func,
    openBuyVideoOverlay: PropTypes.func,
    onSignInRequested: PropTypes.func,
    openSubscribeOverlay: PropTypes.func,
    onPlayRequestedAllowed: PropTypes.func,
    onPlayRequestedDisallowed: PropTypes.func,
    onMemberSignUp: PropTypes.func,

    isChannelCoverView: PropTypes.bool,
    isFirstVideo: PropTypes.bool,

    title: PropTypes.string,
    className: PropTypes.string,
    coverUrl: PropTypes.string,
    itemWidth: PropTypes.number,
    itemHeight: PropTypes.number,

    pictureMode: PropTypes.oneOf(PictureMode.CONTAIN, PictureMode.COVER),
  };

  onCoverClick = () => {
    const {
      isChannelCoverView,
      visitChannel,
      openVideoPage,
      videoItem,
      fedops,
      openMobileOverlay,
    } = this.props;

    if (isChannelCoverView) {
      fedops.interactionStarted(
        FedopsInteractionsNames.MOBILE_OPEN_CHANNEL_VIEW,
      );
      visitChannel(openMobileOverlay);
    } else {
      fedops.interactionStarted(FedopsInteractionsNames.MOBILE_OPEN_VIDEO_VIEW);
      openVideoPage(videoItem.id, openMobileOverlay);
    }
  };

  onBuyClick = notForPreview(() => {
    const { videoItem, openBuyVideoOverlay, openMobileOverlay } = this.props;
    openBuyVideoOverlay(openMobileOverlay, videoItem.id);
  });

  handleSubscribeClick = notForPreview(() => {
    const { openMobileOverlay } = this.props;
    this.props.openSubscribeOverlay(openMobileOverlay);
  });

  render() {
    const {
      isChannelCoverView,
      isFirstVideo,
      onSignInRequested,
      onPlayRequestedAllowed,
      onPlayRequestedDisallowed,
      onMemberSignUp,
      itemWidth,
      itemHeight,
      coverUrl,
      videoItem,
      channelData,
      currentSiteUser,
      title,
      pictureMode,
    } = this.props;

    return (
      <div
        style={{
          width: itemWidth,
          height: itemHeight,
          position: 'relative',
        }}
      >
        <Picture src={coverUrl} mode={pictureMode}>
          <CoverActionsOverlay
            onPlayRequestedAllowed={onPlayRequestedAllowed}
            onPlayRequestedDisallowed={onPlayRequestedDisallowed}
            onMemberSignUp={onMemberSignUp}
            onSignInRequested={onSignInRequested}
            onSubscribeClick={this.handleSubscribeClick}
            onCoverClick={this.onCoverClick}
            onBuyClick={this.onBuyClick}
            videoItem={videoItem}
            channelData={channelData}
            currentSiteUser={currentSiteUser}
            title={title}
            isChannelCoverView={isChannelCoverView}
            isFirstVideo={isFirstVideo}
          />
        </Picture>
      </div>
    );
  }
}

export default withFedopsLogger(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(withMobileOverlay(CoverWithOverlay)),
);
